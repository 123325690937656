import { useState, useEffect, useRef } from 'react';
import { fetchUtilityBillRequest, getProviderDetailRequest, getProviderListRequest, payUtilityBillRequest } from '../../utils/api';
import { isEmpty } from '../../utils/common';
import OfferBanner from '../OfferBanner';
import { AlertWrapper,TableWrapper } from '../UI/styledConstants';
import { Wrapper } from './style';
// import { providerTypeOptions } from './constants';
import Loader from '../UI/Loader/Loader';
import ElectricityReceipt from './ElectricityReceipt';
import CustomDropdown from '../CustomDropdown/CustomDropdown';
import IconClose from '../../assets/images/IconClose';

const initialControls = {
	providerType: '',
	account: '',
	billNumber: '',
	consumerMobileNumber: '',
	eroNumber: '',
	billerId: '',
	userId: '',
	amount: '',
	clientId: '',
	dateOfBirth: '',
	billPaymentRequest: '',
	emailId: '',
	mobile:'',
};

export default function Component({ className = '', BillType = '', billHeading = '' }) {
	const [operatorList, setOperatorList] = useState([]);
	const [billDetails, setBillDetails] = useState({});
	const [payDaital, setPayDaital] = useState('');
	const [controls, setControls] = useState({ ...initialControls });
	const [message, setMessage] = useState({});
	const [loading, setLoading] = useState(false);
	const [showGetBill, setShowGetBill] = useState(false);
	const [dynamicField, setDynamicField] = useState([]);
	const [dynamicFieldValues, setDynamicFieldValues] = useState({});
	const [heading, setHeading] = useState('');
	const [provider, setProvider] = useState('');
	const [activeMobilePop, setActiveMobilePop] = useState(false);

	const numberReg = /^[0-9]+$/;
	const amountReg = /^[0-9.]+$/;
	const emailReg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;


		useEffect(() => {
			if(!isEmpty(payDaital)){
				const form = document.forms['my_form'];
			form.elements['encrypt'].value = payDaital;
			form.submit();
			}
		  }, [payDaital]);
		


	useEffect(() => {
		setControls({
			...controls,
			providerType: BillType,
		});

		setHeading(billHeading);
	}, []);

	//for static field
	const onChangeHandler = async (e) => {
		// if (isEmpty(sessionStorage.getItem('ID Token'))) {
		// 	setMessage({ type: 'error', msg: 'Please login first to continue', place: 'globle' });
		// 	return;
		// }
		const { name, value } = e.target;
		if (name === 'consumerMobileNumber') {
			if (!numberReg.test(value) && value) {
				return false;
			}
		}
		if (name === 'amount') {
			if (!amountReg.test(value) && value) {
				return false;
			}
		}

		const _controls = {
			...controls,
			[name]: value,
		};

		// if (name === 'billerId') {
		// 	if (isEmpty(value)) {
		// 		return;
		// 	}

		// 	providerDaitals(value);
		// }

		setControls(_controls);
	};

	function providerDaitals(billerId) {
		setLoading(true);
		getProviderDetailRequest(billerId).then((resp) => {
			setLoading(false);
			if (resp?.success) {
				if (resp?.data?.data?.parameters.length > 0) {
					const _dynamicField = resp?.data?.data?.parameters;
					setDynamicField([..._dynamicField]);
				}
				if (resp?.data?.data?.fetchRequirement === 'MANDATORY') {
					setShowGetBill(true);
				} else {
					setShowGetBill(false);
				}
			} else {
				setShowGetBill(false);
				if (resp?.errorCodeList.length > 0) {
					setMessage({ type: 'error', msg: resp?.errorCodeList[0], place: 'globle' });
				} else {
					setMessage({ type: 'error', msg: resp?.msg, place: 'globle' });
				}
			}
		});
	}

	const fetchUtilityBill = () => {
		if (controls.providerType === '') {
			setMessage({ type: 'error', msg: 'Please select provider type', place: 'form' });
			return;
		} else if (controls.billerId === '') {
			setMessage({ type: 'error', msg: 'Please select operator', place: 'form' });
			return;
		} else if (BillType === 'INSURANCE' && !emailReg.test(controls.emailId)) {
			setMessage({ type: 'error', msg: 'Please enter valid email', place: 'form' });
			return;
		}

		var returnValue = dynamicField.map((val) => {
			if (val.desc === 'Amount') {
				setDynamicFieldValues({
					...dynamicFieldValues,
					[val.name]: 0,
				});
			} else {
				if (!dynamicFieldValues[val.name]) {
					setMessage({ type: 'error', msg: 'Please enter' + val.desc, place: 'form' });
					return false;
				} else if (val?.regex !== '') {
					var regex = new RegExp(val?.regex);
					let text = dynamicFieldValues[val.name];
					if (!regex.test(text)) {
						setMessage({ type: 'error', msg: 'Please enter currect' + val.desc, place: 'form' });
						return false;
					}
				}
			}
		});

		if (returnValue.includes(false)) {
			return;
		}
		setLoading(true);

		const params = {
			amount: 0,
			inputParameters: dynamicFieldValues,
			billerId: controls.billerId,
			consumerPan: '',
			enquiryReferenceId: '',
			externalRef: '',
			geoCode: '28.6326,77.2175',
			merchantId: '',
			paymentMode: '',
			postalCode: '',
			remarks: '',
			serviceName: '',
			userId: '',
		};

		if (BillType === 'INSURANCE') {
			params.emailId = controls.emailId;
		}

		fetchUtilityBillRequest(params).then((response) => {
			setLoading(false);
			if (response?.success) {
				setBillDetails(response?.data);
				setActiveMobilePop(true);
			} else {
				if (response?.errorCodeList.length > 0) {
					setMessage({ type: 'error', msg: response?.errorCodeList[0], place: 'globle' });
				} else {
					setMessage({ type: 'error', msg: response?.msg, place: 'globle' });
				}
			}
		});
	};

	const payUtilityBill = () => {
		if (isEmpty(sessionStorage.getItem('ID Token'))) {
			setMessage({ type: 'error', msg: 'Please login first to continue', place: 'globle' });
			return;
		}
		if (controls.providerType === '') {
			setMessage({ type: 'error', msg: 'Please select provider type', place: 'form' });
			return;
		} else if (controls.billerId === '') {
			setMessage({ type: 'error', msg: 'Please select operator', place: 'form' });
			return;
		} else if (BillType === 'INSURANCE' && !emailReg.test(controls.emailId)) {
			setMessage({ type: 'error', msg: 'Please enter valid email', place: 'form' });
			return;
		} else if (controls.amount === '' && !controls.amount) {
			setMessage({ type: 'error', msg: 'Please enter amount', place: 'form' });
			return;
		
		} else if (controls.mobile === '' && !controls.amount) {
			setMessage({ type: 'error', msg: 'Please enter mobile number', place: 'form' });
			return;
		}

		var returnValue = dynamicField.map((val) => {
			if (val.desc === 'Amount') {
				setDynamicFieldValues({
					...dynamicFieldValues,
					[val.name]: controls.amount,
				});
			} else {
				if (!dynamicFieldValues[val.name]) {
					setMessage({ type: 'error', msg: 'Please enter' + val.desc, place: 'form' });
					return false;
				} else if (val?.regex !== '') {
					//let  myRegExp = "/"+val?.regex+"/";
					var regex = new RegExp(val?.regex);
					let text = dynamicFieldValues[val.name];
					if (!regex.test(text)) {
						setMessage({ type: 'error', msg: 'Please enter currect' + val.desc, place: 'form' });
						return false;
					}
				}
			}
		});
		if (returnValue.includes(false)) {
			return;
		}

		setLoading(true);
		const params = {
			txncurr: "INR",
            amount:  controls.amount,
            emailId: "testuser@gmail.com",
			mobileNumber: controls.mobile,
            requestHashKey: ""
		};

		if (BillType === 'INSURANCE') {
			params.emailId = controls.emailId;
		}
		payUtilityBillRequest(params).then((resp) => {
			setLoading(false);

			if (resp?.success) {
				setPayDaital(resp?.data);
				
			} else {
				if (resp?.errorCodeList.length > 0) {
					setMessage({ type: 'error', msg: resp?.errorCodeList[0], place: 'globle' });
				} else {
					setMessage({ type: 'error', msg: resp?.msg, place: 'globle' });
				}
			}
		});
	};

	const onCloseModal = () => {
		setPayDaital({});
		setControls({ ...initialControls });
		setDynamicField([]);
		setDynamicFieldValues({});
		setShowGetBill(false);
	};

	useEffect(() => {
		if (!isEmpty(message)) {
			setTimeout(() => {
				setMessage({});
			}, 5000);
		}
	}, [message]);

	const onChangeRequiredField = (e) => {
		setDynamicFieldValues({
			...dynamicFieldValues,
			[e.target.name]: e.target.value,
		});
	};

	useEffect(() => {
		async function getProviderList() {
			// if (isEmpty(sessionStorage.getItem('ID Token'))) {
			// 	//setMessage({ type: 'error', msg: 'Please login first to continue',place:"globle" });
			// 	return;
			// }
			if (!isEmpty(controls.providerType)) {
				setLoading(true);
				const response = await getProviderListRequest({ providerType: controls.providerType });
				setLoading(false);
				if (response?.success) {
					setOperatorList(response?.data);
				}
			}
		}
		getProviderList();
	}, [controls.providerType, sessionStorage.getItem('ID Token')]);

	useEffect(() => {
		if (!isEmpty(provider)) {
			const _controls = {
				...controls,
				billerId: provider,
			};
			setControls(_controls);

			providerDaitals(provider);
		}
	}, [provider]);
	const handleFormPlan = (data) => {
		setActiveMobilePop(false);
		setControls({ ...controls, amount: data });
	};
	return (
		<Wrapper className={`tab-content-wrapper ${className}`}>
			{!isEmpty(message) && message.place == 'globle' && (
				<AlertWrapper className={`${message.type} globle`}>
					<span className="close" onClick={() => setMessage({})}>
						×
					</span>
					{message.msg}
				</AlertWrapper>
			)}
			<div className="tab-content-left">
				<h2>{heading} Bill</h2>
			<div className='flex gap-20'>
			<div className="form-field " style={{display:"none"}}>
					<select className="item-select mobile-operator" defaultValue={controls?.providerType} placeholder="Operator" name="providerType">
						<option value={controls?.providerType}>{heading}</option>
					</select>
				</div>

				<div className="form-field">
					<CustomDropdown list={operatorList} setProvider={setProvider} type="bill" setMessage={setMessage} />
				</div>
				{dynamicField &&
					dynamicField.map((val) => {
						if (!(val.desc === 'Amount')) {
							return (
								<div className="form-field">
									<input
										type="text"
										name={val?.name}
										placeholder={val?.desc}
										maxLength={val?.maxLength}
										minLength={val?.minLength}
										onChange={(e) => onChangeRequiredField(e)}
										required
									/>
								</div>
							);
						}
					})}

				{BillType === 'INSURANCE' && (
					<div className="form-field">
						<input type="text" name="emailId" placeholder="Email" value={controls?.emailId} onChange={onChangeHandler} />
					</div>
				)}
				<div className="form-field">
					<input type="text" name="amount" placeholder="Amount" value={controls?.amount} onChange={onChangeHandler} />
				</div>
				<div className="form-field">
					<input type="text" name="mobile" placeholder="Mobile" value={controls?.mobile} onChange={onChangeHandler} />
				</div>
				{showGetBill && (
					<div className="browse-plans" onClick={fetchUtilityBill}>
						Fetch Bill
					</div>
				)}
				<button type="submit" className="button-outline" onClick={() => payUtilityBill()}>
					Continue
				</button>
			</div>
				
				{!isEmpty(message) && message.place == 'form' && (
					<AlertWrapper className={message.type}>
						<span className="close" onClick={() => setMessage({})}>
							×
						</span>
						{message.msg}
					</AlertWrapper>
				)}
				
				{/* <button type="submit" className="button-outline" onClick={() => fetchUtilityBill()}>
					Continue
				</button> */}
			</div>
			<div className={`tab-content-right ${!activeMobilePop ? '' : 'active'}`}>
				<div
					className="d-m-block close-pop"
					onClick={() => {
						setActiveMobilePop(false);
					}}
				>
					<IconClose />
				</div>
				{!isEmpty(billDetails) && (
					<>
						<h2>Bill Details</h2>
						<TableWrapper>
							<table className="table">
								<thead>
									<tr>
										<th>Biller Name</th>
										<th>Biller Number</th>
										<th>Bill Date</th>
										<th>Bill Period</th>
										<th>Due Date</th>
										<th>Due Amount</th>
										<th>Add Balence</th>
									</tr>
								</thead>
								<tbody>
									{!isEmpty(billDetails) && (
										<tr>
											<td className="bold">{billDetails?.customerName}</td>
											<td>{billDetails?.billNumber}</td>
											<td>{billDetails?.billDate}</td>
											<td>{billDetails?.billPeriod}</td>
											<td>{billDetails?.billDueDate}</td>
											<td>&#8377; {billDetails?.billAmount}</td>
											<td>
												<div className="payee-actions flex items-center">
													<button className="button-outline mr20" onClick={() => handleFormPlan(billDetails?.billAmount)}>
														Pay
													</button>
													{/* <button className="button-outline mr20" onClick={() =>payUtilityBill(billDetails?.billAmount) }>
														Pay
													</button> */}
												</div>
											</td>
										</tr>
									)}
								</tbody>
							</table>
						
						</TableWrapper>
					</>
				)}
				{isEmpty(billDetails) && !loading && <OfferBanner />}
			</div>

			{/* {!isEmpty(payDaital) && <ElectricityReceipt payDaital={payDaital} onCloseModal={onCloseModal} />} */}
			{loading && <Loader />}
<form  style={{display:"none"}} name="my_form" action="https://payment.prokart.co.in/initPayment" method="post">
 <input class="form-control" type="text" id="name" name="clientId" value="Nexa_live_ROlEfIkRuKDaBJEF" />
 <input name="returnUrl" value="https://www.test.com/return" />
 <input type="text" name="encrypt" value={payDaital} />
 <button type="submit">Submit</button>
</form>
		</Wrapper>
	);
}
