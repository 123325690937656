import React from 'react';
import './Contactus.css';
import location from '../../assets/images/location.png';
import envelop from '../../assets/images/envelop.png';
import mobilebutton from '../../assets/images/mobilebutton.png';
function Contactus() {
	return (
		<div className="content-wrapper">
			<div className="heading">
				<h2 className="m-auto">Contact Us</h2>
			</div>
			<div className="contactuswrapper">
				<div className="containers">
					<div className="heading-contact text-center">
						<label className="m-auto">
							We’d Love To <strong>Help You</strong>
						</label>
					</div>
					<div className="consumer-top  consumer-middle flex justify-center gap-100">
						<div className="contactus">
								<iframe
						src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d55996.43623832273!2d77.05336876990332!3d28.69630871951376!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d03cc2e43de33%3A0xdd1d6bf8ce354362!2sNew%20Delhi%2C%20Delhi%2C%20110034!5e0!3m2!1sen!2sin!4v1729237161464!5m2!1sen!2sin"
								height="100%"
								width="100%"
								allowfullscreen=""
								loading="lazy"
								referrerpolicy="no-referrer-when-downgrade"
								title='map'>									
								</iframe>
							<div className="contactusdetail">
								<div className="contactaddress">
									<div className="address">
										<label>Registered Address</label>
									</div>
									<div className="address">
										<img src={location} alt='location' />
										 

										<p>F-108 &109 Plot No.1 Aditya, Complex Blk-KP C.C,<br />
										North West Delhi, Delhi- 110034</p>
									</div>
								</div>
								<div className="contactaddress">
									<div className="address">
										<label>Contact Us</label>
									</div>
									<div className="address">
										<img src={envelop} alt='envelop' />
										<p>
											<strong>Email Id :</strong>amanapaypaymentsservicespvtltd@gmail.com
										</p>
									</div>
									<div className="address">
										<img src={mobilebutton} alt='mobilebutton' />
										<p>
											<strong>Phone No :</strong>7836879833

										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Contactus;
