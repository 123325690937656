import React from 'react';
import './Business.css';

const Business = () => {
	return (
		<div className="business-wrapper">
			<div className="heading">
				<h2 className="m-auto">BUSINESS SOLUTIONS</h2>
			</div>
			<div className="businessWrapper">
				<div className="container">
					<div className="top text-center">
						<h2 className="heading-2 mb20 text-center">
							<span className="strong">Earn More by Becoming a Retailer or Distributor with us</span>
						</h2>
						<p>
							A platform that assures you of safety, security and simplicity. Get your business up and running within 72 hours and start generating revenue that will also add value to
							the digital India revolution.
						</p>
					</div>

					<div className="card-box flex justify-center gap-30">
						<div className="card text-center">
							<img className="mb30" src="https://pay2mobile.fra1.digitaloceanspaces.com/b1.png" alt="img-1" />
							<h3>UPI Payment Channel</h3>
							<p>Experience Seamless, Secure and trusted UPI transactions with the highest ever success rates in the country!</p>
						</div>

						<div className="card text-center">
							<img className="mb30" src="https://pay2mobile.fra1.digitaloceanspaces.com/b2.png" alt="img-1" />
							<h3>Recharge & Bill Payment</h3>
							<p>Pay Bills for 1000's of Listed telecom billers ,Electricity board, LPG, DTH, Municipal Service, Insurance & FastTags using BBPS.</p>
						</div>
						<div className="card text-center">
							<img className="mb30" src="https://pay2mobile.fra1.digitaloceanspaces.com/b3.png" alt="img-1" />
							<h3>Domestic Money Transfer</h3>
							<p>Amanapay Payment  Life Merchant app allows distributors and retailers to transfer funds from any account to any other account...</p>
						</div>
						<div className="card text-center">
							<img className="mb30" src="https://pay2mobile.fra1.digitaloceanspaces.com/b4.png" alt="img-1" />
							<h3>Aadhar Payments</h3>
							<p>A payment system which allows merchants to collect payments from a customer using his Aadhaar number and biometric authentication.</p>
						</div>
						<div className="card text-center">
							<img className="mb30" src="https://pay2mobile.fra1.digitaloceanspaces.com/b5.png" alt="img-1" />
							<h3>Merchant System</h3>
							<p>Merchant service providers work as an intermediary between the organization and a person wanting to receive service.</p>
						</div>
						<div className="card text-center">
							<img className="mb30" src="https://pay2mobile.fra1.digitaloceanspaces.com/b6.png" alt="img-1" />
							<h3>Money Transfer</h3>
							<p>Bank transfer (or wire transfer) is a payment method that allows consumers to transfer money to a bank account around the world.</p>
						</div>
					</div>

					<div className="bottom text-center">
						<p>
							We at Amanapay Payment  deliver Aadhaar Enabled Payment systems ( AEPS), Domestic Money Transfer, Recharge, and Bill Payment System of all leading service providers at the
							unbelievably lowest price.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Business;
